<style lang="scss" scoped>
/deep/ .comp-outstock-dialog {
    margin-top: 6vh !important;
    max-width: 1200px;
    .item-card {
        position: relative;
        width: 100%;
        height: auto;
        margin: 15px auto 0;
        padding-bottom: 35px;
        background-color: #f5f5f5;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        overflow: hidden;
        .image {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        .count {
            padding: 0 5px;
            .el-input-number--mini {
                width: 100%;
            }
        }
        .title {
            position: relative;
            height: 60px;
            padding: 0 4px;
            line-height: 1.6;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
        }
        .buttons {
            width: 92%;
            position: absolute;
            bottom: 5px;
            left:4%;
            .el-button--mini.is-circle {
                padding: 4px;
            }
            .btn {
                padding: 5px;
            }
        }
        .el-image {
            width: 100%;
            aspect-ratio: 1.2;
            .image-slot {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .el-input-number--mini .el-input-number__decrease, 
        .el-input-number--mini .el-input-number__increase {
            width: 20px;
        }
    }
}
.assets {
    font-size: 13px;
    border-bottom: 1px dashed #eee;
    padding: 5px 0;
    .fl {
        max-width: 96%;
    }
    .asset-image {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        .error-image {
            font-size: 26px;
            color: #cacaca;
        }
    }
    .asset-title {
        vertical-align: middle;
        line-height: 1.5;
        white-space: normal;
        display: inline-block;
    }
}
</style>

<template>
    <div>
        <el-dialog :title="title" custom-class="c-el-dialog comp-outstock-dialog" :visible.sync="showDialog" 
            :before-close="_close" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body>
            <el-form :model="dialogData" :rules="formRules" :ref="formRefName" label-width="130px">
                <el-row :gutter="10">
                    <el-col :xs="24">
                        <el-form-item label="">
                            <el-row class="mrgb10">
                                <el-button type="primary" :plain="scanCodeType !== 1" @click="scanBarcode()"><i class="fa fa-barcode"></i> 扫条形码</el-button>
                                <el-button type="primary" :plain="scanCodeType !== 2" @click="scanQrCode()"><i class="fa fa-qrcode"></i> 扫二维码</el-button>
                            </el-row>
                            <el-row>
                                <el-autocomplete :ref="refAutoComplete" class="c-pw100" v-model="keyword" :trigger-on-focus="false" clearable :fetch-suggestions="querySearchAsync" 
                                    placeholder="资产名称/条形码" @select="handleSelect" @keyup.enter.native="execSelect()" @clear="clearAutoComplete">
                                    <template slot-scope="{ item }">
                                        <dl class="assets">
                                            <dd class="fl">
                                                <el-image class="asset-image" :src="uploadBaseUrl + item.imageUrl">
                                                    <div slot="error">
                                                        <i class="el-icon-picture-outline error-image"></i>
                                                    </div>
                                                </el-image>
                                                <span class="asset-title">
                                                    <span>{{ mxTranceAssetData(item) }}</span>
                                                    <span :class="mxTranceAssetData(item) ? '' : 'mrgl10'">{{ item.value }}</span>
                                                    <span class="mrgl5 col_danger" v-if="item.maxCount >= 0 && !$root.isPc">【库存：{{ item.maxCount }}】</span>
                                                </span>
                                            </dd>
                                            <dd class="fr col_danger" v-if="item.maxCount >= 0 && $root.isPc">【库存：{{ item.maxCount }}】</dd>
                                            <div class="clearb"></div>
                                        </dl>
                                    </template>
                                </el-autocomplete>
                            </el-row>
                            <el-row :gutter="10">
                                <template v-for="(item, index) in dialogData.items">
                                    <el-col :xs="8" :sm="6" :md="4" :lg="4" :key="'ItemCard' + index">
                                        <div class="item-card">
                                            <el-image :src="uploadBaseUrl + item.imageUrl" :preview-src-list="[uploadBaseUrl + item.imageUrl]" fit="cover">
                                                <div slot="error" class="image-slot">
                                                    <img src="/static/images/icons/image-failed.png" />
                                                </div>
                                            </el-image>
                                            <div class="title">{{ item.assetName }}</div>
                                            <div class="count">
                                                <el-input-number v-model="item.count" :max="item.maxCount" :min="0" v-if="item.maxCount && item.maxCount > 0"
                                                    :disabled="item.needSerialNumber" size="mini"></el-input-number>
                                                <el-input-number v-model="item.count" :min="0" v-else
                                                    :disabled="item.needSerialNumber" size="mini"></el-input-number>
                                            </div>
                                            <div class="buttons">
                                                <el-button class="btn fl" size="mini" type="primary" icon="el-icon-edit" 
                                                    v-if="item.needSerialNumber" @click="openScanCode(item)">序列号</el-button>
                                                <el-button class="btn fr" size="mini" type="danger" icon="el-icon-delete"
                                                    @click="_delete(item, index)"></el-button>
                                                <div class="clearb"></div>
                                            </div>
                                        </div>
                                    </el-col>
                                </template>
                            </el-row>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item label="使用者" prop="assetUserId">
                            <!--<el-select class="mrgr5 mrgb5" :class="$root.isPc ? 'c-el-input' : 'c-pw100'" placeholder="全部属事业部" filterable
                                clearable v-model="dialogData.companyId" @change="changeCompany">
                                <el-option v-for="ws in allCompany" :key="ws.id" :label="ws.name" :value="ws.id"></el-option>
                            </el-select>-->
                            <el-select class="c-pw100" placeholder="请选择使用者" filterable
                                clearable v-model="dialogData.assetUserId">
                                <el-option v-for="ws in allAssetUser" :key="ws.id" :label="ws.name" :value="ws.id">
                                    <span class="fl">
                                        <span class="col_primary dib" style="min-width: 72px;">【{{ ws.category && ws.category.name }}】</span>
                                        {{ ws.name }}
                                        <span class="col_info mrgl5" v-if="ws.phone">{{ ws.phone }}</span>
                                    </span>
                                    <span class="fr col_info">{{ ws.company && ws.company.name }}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :md="12">
                        <el-form-item label="领取人" prop="principal">
                            <el-select class="c-pw100" v-model="dialogData.principal" filterable placeholder="请选择领取人"
                                :default-first-option="true">
                                <el-option v-for="item in allPrincipal" :value="item.name" :key="item.id">
                                    <span class="fl">
                                        {{ item.name }}
                                        <span class="col_info mrgl5" v-if="item.phone">{{ item.phone }}</span>
                                    </span>
                                    <span class="fr col_info">{{ item.company && item.company.name }}</span>
                                </el-option>
                            </el-select>
                            <!--<el-autocomplete class="c-pw100 mrgr5 mrgb5" placeholder="请输入领取人(可从使用者中快速选择)" :trigger-on-focus="true"
                                clearable v-model="dialogData.principal" :fetch-suggestions="querySearchPrincipal"></el-autocomplete>-->
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="附件" prop="attachments">
                            <multiple-images :imageUrls="dialogData.attachments" :folder="'outstock'" :multiple="true" @emitImageUrls="setImageUrls(arguments)"></multiple-images>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" :rows="3" v-model="dialogData.remark" placeholder="请输入备注"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer">
                <el-button @click="_close()">取消</el-button>
                <el-button type="primary" @click="_prevSubmit()">提交</el-button>
                <!--<el-button type="primary" @click="_save()">提交</el-button>-->
            </span>
        </el-dialog>
        
        <!--扫码getScanSerials-->
        <scan-serial :ref="refToScanSerial" @scanSerials="setAssetDatas"></scan-serial>
        <!--调用摄像头扫码-->
        <html-scan :ref="refHtmlScan" @success="htmlScanSuccess(arguments)"></html-scan>
    </div>
</template>

<script>
import stockCommon from "./js/stockCommon"
import MultipleImages from "@/components/ImageMultipleUpload"
import ScanSerial from "./scanSerial"
import HtmlScan from "@/components/HtmlScan"
import { getStockCompany, getStockWarehourse } from "@/utils/storages"
import * as funCommon from "@/api/common"
import * as funInfomation from "@/api/information"
import * as funStock from "@/api/stock"
export default {
    name: "compStockOut",
    mixins: [stockCommon],
    components: { MultipleImages, ScanSerial, HtmlScan },
    data() {
        return {
            refToScanSerial: "refStockOutToScanSerial",
            refAutoComplete: "refStockOutToAutoComplete",
            refHtmlScan: "refStockOutToHtmlScan",
            showDialog: false,
            companyItem: {},
            warehourseItem: {},
            dialogData: {},
            defaultDialogData: {
                companyId: "",
                warehouseId: "",
                assetUserId: "",
                principal: "",
                remark: "",
                items: [],
                attachments: []
            },
            formRefName: "refStockOut",
            formRules: {
                assetUserId: [{ required: true, message: '请选择使用者', trigger: ['blur', 'change'] }],
                warehouseId: [{ required: true, message: '请选择仓库', trigger: ['blur', 'change']}],
                principal: [{ required: true, message: '请选择领取人', trigger: ['blur', 'change'] }]
            },
            allCompany: [],
            allAssetUser: [],
            allPrincipal: [],
            keyword: "",
            title: "",
            scanCodeType: 1
        }
    },
    methods: {
        async open(row) {
            this.title = "出库"
            this.scanCodeType = 1
            this.dialogData = JSON.parse(JSON.stringify(this.defaultDialogData))
            // 默认仓库
            this.companyItem = getStockCompany()
            this.warehourseItem = getStockWarehourse()
            this.dialogData.companyId = this.companyItem && this.companyItem.id || ""
            this.dialogData.warehouseId = this.warehourseItem && this.warehourseItem.id || ""
            if (this.warehourseItem && this.warehourseItem.id) {
                this.title = `出库【${this.companyItem.name}-${this.warehourseItem.name}】`
            }
            await this.getAllPrincipal()
            await this.getAllCompany()
            await this.getAllAssetUser()
            // 只有一个时，默认选择使用者
            if (this.allAssetUser.length === 1) {
                this.dialogData.assetUserId = this.allAssetUser[0].id
            }
            this.showDialog = true
        },
        _prevSubmit(isSave) {
            // 使用者验证，出库必传
            if (!this.dialogData.assetUserId) {
                this.errorMsg(`请选择出库接收对象【使用者】。`)
                return false
            }
            this.$refs[this.formRefName].validate(valid => {
                if (valid) {
                    // 验证资产
                    if (this.dialogData.items.length <= 0) {
                        this.errorMsg(`请添加需要出库的资产。`)
                        return false
                    }
                    // 验证上传的数量
                    let warnTemp = undefined
                    for ( let i in this.dialogData.items) {
                        if (this.dialogData.items[i].count <= 0) {
                            warnTemp = this.dialogData.items[i]
                            break
                        }
                    }
                    if (warnTemp && warnTemp.count <= 0) {
                        this.errorMsg(`${warnTemp.assetName}，出库数量必须大于0，否则请删除!`)
                        return false
                    }
                    this._create()
                } else {
                    return false
                }
            })
        },
        _create() {
            let commitData = {
                principal: this.dialogData.principal,
                subject: "出库",
                items: this.dialogData.items.map(x => {
                    let temp = { 
                        assetId: x.assetId, 
                        count: x.count, 
                        serialNumbers: x.serialNumbers 
                    }
                    return temp
                }) || []
            }
            window.$common.fullLoading()
            funStock.setStockHandleToken(commitData).then((res) => {
                let token = window.$util.getUrlParam(res, "token") || ""
                if (token == "") {
                    this.errorMsg("Token参数错误!")
                    return false
                }
                window.$common.closeFullLoading()
                let imageTemp = this.setQRCodeImage(res)
                let tips = `<div class="col_danger">关闭后，再次打开需要重新扫码授权</div>`
                this.alert(`<img src="${imageTemp.data}" />${tips}`, "请领取人用微信扫码确认", {
                    type: " ",
                    dangerouslyUseHTMLString: true,
                    center: true,
                    showCancelButton: false,
                    cancelButtonText: "关闭扫码",
                    confirmButtonText: "确认出库",
                    beforeClose: (action, instance, done) => {
                        if (action === "confirm") {
                            this._save(token, () => {
                                done()
                            })
                        } else {
                            done()
                        }
                    }
                })
            })
        },
        _save(token, callback) {
            let commitData = {
                warehouseId: this.dialogData.warehouseId,
                principal: this.dialogData.principal,
                remark: this.dialogData.remark,
                attachments: this.dialogData.attachments || [],
                items: this.dialogData.items.map(x => {
                    let temp = { 
                        assetId: x.assetId, 
                        count: x.count, 
                        serialNumbers: x.serialNumbers 
                    }
                    return temp
                }) || [],
                assetUserId: this.dialogData.assetUserId,
                handleAuthToken: token
            }
            window.$common.fullLoading()
            funStock.outStock(commitData).then((res) => {
                window.$common.closeFullLoading()
                callback && callback()
                setTimeout(() => {
                    this.commonSuccess("出库操作成功!", res)
                }, 100)
            })
        }
    }
}
</script>